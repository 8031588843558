<template>
  <div class="info-card" >
    <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="queryForms" :rules="formRules" :label-col="{span:9}" :wrapper-col="{span:14}" v-show="show">
      <div class="card-content">
        <h3 style="font-weight: bold; color: #333;">一键修改</h3>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;">
          <span style="margin-left: 2%">电梯信息</span>
        </h3>
        <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :label-col="{span:9}" :wrapper-col="{span:14}">
        <a-form-model-item label="救援代码" prop="liftrescue">
          <a-input  v-model="queryForms.liftrescue" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="电梯注册码" prop="liftnum">
          <a-input v-model="queryForms.liftnum" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="安装地址" prop="adress">
          <a-input v-model="queryForms.adress" placeholder=""></a-input>
        </a-form-model-item>
          <a-form-model-item label="" prop="callertel">
            <a-button type="primary" @click="liftSelect">选择</a-button>
          </a-form-model-item>
        </a-form-model>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
          <span style="margin-left: 2%">参数信息</span>
        </h3>
        <a-form-model-item label="电梯品牌" prop="brandname">
          <a-input v-model="queryForms.brandname" read-only="read-only" placeholder="请点击以选择电梯品牌" @click="findBrand"></a-input>
        </a-form-model-item>
        <a-form-model-item label="用户设备类别" prop="devicetypeid">
          <a-select v-model="queryForms.devicetypeid">
            <a-select-option v-for="(item, index) in devicetypeidOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="电梯重要等级" prop="level">
          <a-select placeholder="请选择" v-model="queryForms.level">
            <a-select-option value="0">高</a-select-option>
            <a-select-option value="1">一般</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="使用状态" prop="status">
          <a-select placeholder="请选择" v-model="queryForms.status">
            <a-select-option value="0">未使用</a-select-option>
            <a-select-option value="1">使用</a-select-option>
          </a-select>
        </a-form-model-item>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
          <span style="margin-left: 2%">使用单位信息</span>
        </h3>
        <a-form-model-item label="使用单位" prop="userdepid">
          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='1'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype=='1'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="使用单位电话" prop="telphone">
          <a-input v-model="queryForms.telphone" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="负责人" prop="userdeppreson">
          <a-input  v-model="queryForms.userdeppreson" placeholder="" style="width:100%;margin-top: -100px"></a-input>
        </a-form-model-item>
        <a-form-model-item label="负责人电话" prop="userdeppersonphone">
          <a-input v-model="queryForms.userdeppersonphone" placeholder=""></a-input>
        </a-form-model-item>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
          <span style="margin-left: 2%">设备维保信息</span>
        </h3>
        <a-form-model-item label="维保单位" prop="maintenancedepid">
          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='3'?deptOptions.filter(item => item.deptype=='3'):deptOptionsAll.filter(item => item.deptype=='3')" v-model="maintenancedepidCascaderSelected" :load-data="userInfo.usertype=='3'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择维保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="维保单位电话" prop="maintenancedephone">
          <a-input v-model="queryForms.maintenancedephone" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保负责人一" prop="maintenanceperson1">
          <a-input v-model="queryForms.maintenanceperson1" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保负责人一电话" prop="maintenancephone1">
          <a-input v-model="queryForms.maintenancephone1" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保负责人二" prop="maintenanceperson2">
          <a-input v-model="queryForms.maintenanceperson2" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保负责人二电话" prop="maintenancephone2">
          <a-input v-model="queryForms.maintenancephone2" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保区域经理" prop="areamanager">
          <a-input v-model="queryForms.areamanager" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保区域经理电话" prop="areamanagerphone">
          <a-input v-model="queryForms.areamanagerphone" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保单位经理" prop="depmanager">
          <a-input v-model="queryForms.depmanager" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="维保单位经理电话" prop="depmanagerphone">
          <a-input v-model="queryForms.depmanagerphone" placeholder=""></a-input>
        </a-form-model-item>
        <h3 style="font-weight: bold; background:#DCDBDC; width:99%; color: #333;margin-top: 1%">
          <span style="margin-left: 2%">二级救援信息</span>
        </h3>
        <a-form-model-item label="应急处置单位" prop="rescuedepid">
          <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='6'?deptOptions.filter(item => item.deptype=='6'):deptOptionsAll.filter(item => item.deptype=='6')" v-model="rescuedepidCascaderSelected" :load-data="userInfo.usertype=='6'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择应急处置单位" :change-on-select="true" :allow-clear="false"></a-cascader>
        </a-form-model-item>
        <a-form-model-item label="二级救援站电话" prop="pristationphone">
          <a-input v-model="queryForms.pristationphone" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="驻站人一" prop="stationperson1">
          <a-input v-model="queryForms.stationperson1" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="驻站人一电话" prop="stationphone1">
          <a-input v-model="queryForms.stationphone1" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="驻站人二" prop="stationperson1">
          <a-input v-model="queryForms.stationperson2" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="驻站人二电话" prop="stationphone2">
          <a-input v-model="queryForms.stationphone2" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="救援站负责人" prop="pristationperson">
          <a-input  v-model="queryForms.pristationperson" placeholder=""></a-input>
        </a-form-model-item>
        <a-form-model-item label="救援站负责人电话" prop="pristationperphone">
          <a-input v-model="queryForms.pristationperphone" placeholder=""></a-input>
        </a-form-model-item>
      </div>
      <div class="showbutton" style="text-align: center">
        <a-button type="primary"  @click="save()">保存</a-button>
        <a-button @click="exit">关闭</a-button>
      </div>
    </a-form-model>
    <liftSelect :visible.sync="liftselectVisible" ref="liftselect" :LiftData="liftData" @upDate="change"></liftSelect>
    <brand-select :visible.sync="brandselectVisible" ref="brandselect" @upDate="change1"></brand-select>
  </div>
</template>
<script>
import moment from "moment";
import alarmComplaint from "V/yjcz/emergency/alarmComplaint";
import alarmConsult from "V/yjcz/emergency/alarmConsult";
import liftSelect from "V/yjcz/emergency/liftSelect";
import {getCache} from "U/index";
import {getLiftinfoById, getLiftListByCondition, modifyLiftinfo, modifyUserdep} from "A/jcgn";
import BrandSelect from "C/modals/brandSelect";
import {mapState} from "vuex";
import {getDictByDicType, getMentenacegroupListByCondition} from "A/xtpz";
import areaselect from "@/mixins/areaselect";
import deptselect from "@/mixins/deptselect";
import callCenter from "C/modals/callCenter";

export default {
  name: "modify",
  mixins: [areaselect, deptselect],
  components: {
    BrandSelect,
    alarmComplaint,
    alarmConsult,
    liftSelect
  },
  data() {
    return {
      moment,
      pages: '',
      show:true,
      username:'',
      liftselectVisible:false,
      brandselectVisible:false,
      liftData:'',

      //电梯设备类别
      devicetypeidOptions: [],
      //电梯品牌
      brandidOptions: [],
      dataSource:[],
      //使用单位
      userdepidCascaderSelected: [],
      //维保单位
      maintenancedepidCascaderSelected: [],
      //救援单位
      rescuedepidCascaderSelected: [],

      queryForms:{
        //电梯
        liftid:'',
        liftrescue:'',
        liftnum :'',
        adress:'',
        brandname:'',
        devicetypename:'',
        devicetypeid:'',
        level:'',
        status:'',
        brandid:'',

        //使用单位
        userdepid:'',
        levelid:'',
        userdepname:'',
        telphone:'',
        userdeppreson :'',
        userdeppersonphone:'',

        //维保单位
        maintenancedepid:'',
        maintenancelevelid:'',
        maintenancedepname :'',
        maintenancedephone :'',
        rescuepreson :'',
        rescuephone:'',
        safeperson:'',
        safephone:'',
        maintenanceperson1:'',
        maintenancephone1:'',
        maintenanceperson2:'',
        maintenancephone2:'',
        areamanager:'',
        areamanagerphone:'',
        depmanager:'',
        depmanagerphone:'',

        //救援单位
        rescuedepid:'',
        rescuedeplevelid:'',
        rescuedepname:'',
        pristationphone:'',
        stationperson1:'',
        stationphone1:'',
        stationperson2:'',
        stationphone2:'',
        pristationperson:'',
        pristationperphone:'',

        // 基础信息
        traceabilitynum: '',
        devicecode: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        liftcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        adminuserdepid: '',
        coordinatex: '',
        coordinatey: '',
        placeid: '',
        // 设备信息
        devicemodelid: '',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        floorcount: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        estatemodelid: '',
        communityid: '',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 检验信息
        examinedepid: '',
        examineperson: '',
        examinetime: '',
        nextexaminetime: '',
        result: '',
        resultdesc: '',
        examinenum: '',
        testdepid: '',
        testexamineperson: '',
        testexaminetime: '',
        testnextexaminetime: '',
        testresult: '',
        testresultdesc: '',
        testexaminenum: '',
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // IP语音网关和可视通讯
        ipnetnum: '',
        videonum: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checkunit: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturerid: '',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
        // 审核状态
        checkstatus: '0',
        // 关联设备
        deviceid: '',
        linkiot:'',
      },
      formRules: {
        brandname: [{required: true, message: '请输入电梯品牌',trigger:'blur,change'}],
        level: [{required: true, message: '请选择电梯重要等级',trigger:'blur,change'}],
        devicetypename: [{required: true, message: '请选择用户设备类别',trigger:'blur,change'}],
        status: [{required: true, message: '请选择使用状态',trigger:'blur,change'}],
        userdepname: [{required: true, message: '请选择使用单位',trigger:'blur,change'}],
        maintenancedepname: [{required: true, message: '请选择维保单位',trigger:'blur,change'}],
      }
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
  },
  watch: {
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryForms.userdepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('userdepid')
        let deptInfo = this.getDeptInfoFormDeptOptionsByDeptid(this.queryForms.userdepid, this.userInfo.usertype!='1');
        if(deptInfo) {
          this.queryForms.userdepid=deptInfo.userdepid || '';
          this.queryForms.userdepname=deptInfo.userdepname || '';
          this.queryForms.telphone=deptInfo.telphone || '';
          this.queryForms.levelid=deptInfo.levelid || '';
          this.queryForms.safephone=deptInfo.safeperson || '';
        }
      }else {
        this.queryForms.userdepid='';
        this.queryForms.userdepname='';
        this.queryForms.telphone='';
        this.queryForms.levelid='';
        this.queryForms.safephone='';
      }
    },
    maintenancedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryForms.maintenancedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('maintenancedepid')
        this.initMaintenancegroupidOptions();
        let deptInfo = this.getDeptInfoFormDeptOptionsByDeptid(this.queryForms.maintenancedepid, this.userInfo.usertype!='3');
        if(deptInfo) {
          console.log(deptInfo)
          this.queryForms.maintenancedephone=deptInfo.telphone || '';
          this.queryForms.maintenanceperson1=deptInfo.maintenanceperson1 || '';
          this.queryForms.maintenancephone1=deptInfo.maintenancephone1 || '';
          this.queryForms.maintenanceperson2=deptInfo.maintenanceperson2 || '';
          this.queryForms.maintenancephone2=deptInfo.maintenancephone2 || '';
          this.queryForms.areamanager=deptInfo.areamanager || '';
          this.queryForms.areamanagerphone=deptInfo.areamanagerphone || '';
          this.queryForms.depmanager=deptInfo.depmanager || '';
          this.queryForms.depmanagerphone=deptInfo.depmanagerphone || '';
        }
      }else {
        this.queryForms.maintenancedepid = '';
        this.maintenancegroupidOptions = [];
      }
    },
    rescuedepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryForms.rescuedepid = val[val.length-1];
        this.$refs.modalForm.clearValidate('rescuedepid')
        let deptInfo = this.getDeptInfoFormDeptOptionsByDeptid(this.queryForms.rescuedepid, this.userInfo.usertype!='6');
        if(deptInfo) {
          this.queryForms.stationperson1=deptInfo.stationperson1 || '';
          this.queryForms.stationphone1=deptInfo.stationphone1 || '';
          this.queryForms.stationperson2=deptInfo.stationperson2 || '';
          this.queryForms.stationphone2=deptInfo.stationphone2 || '';
          this.queryForms.pristationperson=deptInfo.pristationperson || '';
          this.queryForms.pristationperphone=deptInfo.pristationperphone || '';
          this.queryForms.pristationphone=deptInfo.telphone || '';
        }
      }else {
        this.queryForms.rescuedepid = '';
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    findBrand() {
      this.brandselectVisible = true
    },
    init() {
      this.initData();
      this.initDevicetypeidOptions();
      this.initMaintenancetypeOptions();
      this.initBrandidOptions();
      this.initDeptOptionsAll();
    },
    initData() {
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.username = logininfo.usernames;
      }
    },
    onChange(value, dateString) {
      this.queryForms.calledtime = dateString
    },
    liftSelect() {
      this.liftselectVisible = true;
    },
    save() {
      if (this.queryForms.brandid == null) {
        this.$message.info("电梯品牌不能为空")
      } else if (this.queryForms.devicetypename == null) {
        this.$message.info("用户设备类别不能为空")
      } else if (this.queryForms.level == null) {
        this.$message.info("电梯重要等级不能为空")
      } else if (this.queryForms.status == null) {
        this.$message.info("运行状态不能为空")
      } else if (this.queryForms.userdepid == null) {
        this.$message.info("使用单位不能为空")
      } else if (this.queryForms.maintenancedepid == null) {
        this.$message.info("维保单位不能为空")
      } else {
        let params = {
          liftid: this.queryForms.liftid,
          liftrescue: this.queryForms.liftrescue,
          liftnum: this.queryForms.liftnum,
          userdeppreson: this.queryForms.userdeppreson,
          userdeppersonphone: this.queryForms.userdeppersonphone,
          checkstatus: 2,
          adress: this.queryForms.adress,
          brandid: this.queryForms.brandid,
          brandname: this.queryForms.brandname,
          devicetypename: this.queryForms.devicetypename,
          devicetypeid: this.queryForms.devicetypeid,
          level: this.queryForms.level,
          status: this.queryForms.status,
          maintenancedepid: this.queryForms.maintenancedepid,
          rescuedepid: this.queryForms.rescuedepid,
          userdepid: this.queryForms.userdepid,
          linkiot:this.queryForms.linkiot
        }
        modifyLiftinfo(params)
        let params1 = {
          userdepid: this.queryForms.userdepid,
          userdepname: this.queryForms.userdepname,
          levelid: this.queryForms.levelid,
          safeperson: this.queryForms.safeperson,
          safephone: this.queryForms.safephone,
          telphone: this.queryForms.telphone,
          deptype: 1,
        }
        modifyUserdep(params1)
        let params3 = {
          userdepid: this.queryForms.maintenancedepid,
          userdepname: this.queryForms.maintenancedepname,
          levelid: this.queryForms.maintenancelevelid,
          telphone: this.queryForms.maintenancedephone,
          rescuepreson: this.queryForms.rescuepreson,
          rescuephone: this.queryForms.rescuephone,
          safeperson: this.queryForms.safeperson,
          safephone: this.queryForms.safephone,
          maintenanceperson1: this.queryForms.maintenanceperson1,
          maintenancephone1: this.queryForms.maintenancephone1,
          maintenanceperson2: this.queryForms.maintenanceperson2,
          maintenancephone2: this.queryForms.maintenancephone2,
          areamanager: this.queryForms.areamanager,
          areamanagerphone: this.queryForms.areamanagerphone,
          depmanager: this.queryForms.depmanager,
          depmanagerphone: this.queryForms.depmanagerphone,
          deptype: 3,
        }
        modifyUserdep(params3)
        let params6 = {
          userdepid: this.queryForms.rescuedepid,
          levelid: this.queryForms.rescuedeplevelid,
          userdepname: this.queryForms.rescuedepname,
          telphone: this.queryForms.pristationphone,
          stationperson1: this.queryForms.stationperson1,
          stationphone1: this.queryForms.stationphone1,
          stationperson2: this.queryForms.stationperson2,
          stationphone2: this.queryForms.stationphone2,
          pristationperson: this.queryForms.pristationperson,
          pristationperphone: this.queryForms.pristationperphone,
          deptype: 6,
        }
        modifyUserdep(params6)
        this.$message.success("修改成功")
      }
      var that=this;
      (function () {
        setTimeout(function () {
          that.change(that.liftid)
        }, 200);
      })();
    },
    initDevicetypeidOptions() {
      let params = {
        liftpropertytype: '1',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.devicetypeidOptions = res.item;
        }
      })
    },
    initBrandidOptions() {
      let params = {
        liftpropertytype: '3',
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.dataSource = res.item;
        }
      })
    },
    initMaintenancetypeOptions() {
      let params = {
        dictype: '14',
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancetypeOptions = res.item;
        }
      })
    },
    initMaintenancegroupidOptions() {
      let params = {
        maintenancedepid: this.queryForms.maintenancedepid,
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          this.maintenancegroupidOptions = res.item;
        }
      })
    },
    exit() {
      this.$router.go(-1)
    },
    change1(msg){
      this.queryForms.brandid=msg[0].liftpropertyid
      this.queryForms.brandname=msg[0].value
    },
    change(msg) {
      this.liftid = msg;
        let params = {
          liftid: msg
        }
        getLiftinfoById(params).then(res => {
          if (res && res.returncode == '0') {
            // this.queryForms = res.item
            this.queryForms.liftrescue=res.item.liftrescue
            this.queryForms.userdepid=res.item.userdepid
            this.queryForms.maintenancedepid=res.item.maintenancedepid
            this.queryForms.rescuedepid=res.item.rescuedepid
            this.queryForms.liftnum=res.item.liftnum
            this.queryForms.adress=res.item.adress
            this.queryForms.brandid=res.item.brandid
            this.queryForms.brandname=res.item.brandname
            this.queryForms.devicetypeid=res.item.devicetypeid
            this.queryForms.userdeppreson=res.item.userdeppreson
            this.queryForms.userdeppersonphone=res.item.userdeppersonphone
            this.queryForms.liftid = res.item.liftid
            this.queryForms.level = res.item.level.toString()
            this.queryForms.status = res.item.status.toString()
            this.queryForms.linkiot = res.item.linkiot
            if(res.item.userdepid) {
              this.initDeptOptionsByUserdepid(res.item.userdepid, 'userdepidCascaderSelected', this.userInfo.usertype!='1')
              if(res.item.userdepLists) {
                // this.queryForms.userdepid=res.item.userdepLists.userdepid || '';
                // this.queryForms.userdepname=res.item.userdepLists.userdepname || '';
              }
            }
            if(res.item.maintenancedepid) {
              this.initDeptOptionsByUserdepid(res.item.maintenancedepid, 'maintenancedepidCascaderSelected', this.userInfo.usertype!='3')
              // 维保单位信息
              if(res.item.maintenancedepLists) {
                this.queryForms.maintenancedepid=res.item.maintenancedepLists.userdepid || '';
                this.queryForms.maintenancedepname=res.item.maintenancedepLists.userdepname || '';
                this.queryForms.maintenancelevelid=res.item.maintenancedepLists.levelid || '';
              }
            }
            if(res.item.rescuedepid) {
              this.initDeptOptionsByUserdepid(res.item.rescuedepid, 'rescuedepidCascaderSelected', this.userInfo.usertype!='6')
              if(res.item.rescuedepLists) {
                this.queryForms.rescuedepid=res.item.rescuedepLists.userdepid || '';
                this.queryForms.rescuedepname=res.item.rescuedepLists.userdepname || '';
                this.queryForms.rescuedeplevelid=res.item.rescuedepLists.levelid || '';
              }
            }
            // this.initDeptOptionsByUserdepid(res.item.userdepid, 'userdepidCascaderSelected', this.userInfo.usertype != '1')
            // this.initDeptOptionsByUserdepid(res.item.maintenancedepid, 'maintenancedepidCascaderSelected', this.userInfo.usertype != '3')
            // this.initDeptOptionsByUserdepid(res.item.rescuedepid, 'rescuedepidCascaderSelected', this.userInfo.usertype != '6')
            // this.queryForms.userdepid = res.item.userdepLists.userdepid
            // this.queryForms.userdepname = res.item.maintenancedepLists.userdepname
            // this.queryForms.telphone = res.item.userdepLists.telphone
            // this.queryForms.levelid = res.item.userdepLists.levelid
            // this.queryForms.safeperson = res.item.userdepLists.safeperson
            // this.queryForms.safephone = res.item.userdepLists.safephone
            // this.queryForms.maintenancedepid = res.item.maintenancedepLists.userdepid
            // this.queryForms.maintenancedepname = res.item.maintenancedepLists.userdepname
            // this.queryForms.maintenancelevelid = res.item.maintenancedepLists.levelid
            // this.queryForms.maintenancedephone = res.item.maintenancedepLists.telphone
            // this.queryForms.maintenanceperson1 = res.item.maintenancedepLists.maintenanceperson1
            // this.queryForms.maintenancephone1 = res.item.maintenancedepLists.maintenancephone1
            // this.queryForms.maintenanceperson2 = res.item.maintenancedepLists.maintenanceperson2
            // this.queryForms.maintenancephone2 = res.item.maintenancedepLists.maintenancephone2
            // this.queryForms.areamanager = res.item.maintenancedepLists.areamanager
            // this.queryForms.areamanagerphone = res.item.maintenancedepLists.areamanagerphone
            // this.queryForms.depmanager = res.item.maintenancedepLists.depmanager
            // this.queryForms.depmanagerphone = res.item.maintenancedepLists.depmanagerphone
            // this.queryForms.pristationid = res.item.rescuedepLists.userdepid
            // this.queryForms.pristationlevelid = res.item.rescuedepLists.levelid
            // this.queryForms.pristationname = res.item.rescuedepLists.userdepname
            // this.queryForms.phone = res.item.rescuedepLists.telphone
            // this.queryForms.stationperson1 = res.item.rescuedepLists.stationperson1
            // this.queryForms.stationphone1 = res.item.rescuedepLists.stationphone1
            // this.queryForms.stationperson2 = res.item.rescuedepLists.stationperson2
            // this.queryForms.stationphone2 = res.item.rescuedepLists.stationphone2
            // this.queryForms.pristationperson = res.item.rescuedepLists.pristationperson
            // this.queryForms.pristationperphone = res.item.rescuedepLists.pristationperphone
            // this.queryForms.pristation = res.item.rescuedepLists.pristation
            // this.queryForms.pristationphone = res.item.rescuedepLists.pristationphone
          }
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.card-content {
  padding-bottom: 15px;
  padding-left: 15px;
}
</style>